export interface PaginatorSetting {
  length: number;
  pageSize: number;
  pages: number;
  skip: number;
}

export interface Count {
  count: number;
}

export enum Roles {
  supervisor = "supervisors",
  lawyer = "lawyers",
  conciliator = "conciliators",
  callcenter = "callcenter",
}

export enum SocketEvents {
  CONNECT = "connectToLawyersQueue",
  DISCONNECT = "disconnectFromLawyersQueue",
  UPDATE = "updateLawyerStatus",
  GET_QUEUE = "getAvailableQueue",
}
