import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { debounceTime, Subject, Subscription } from "rxjs";
import { GlobalSearchService } from "../../services/global-search.service";
import { GlobalSearchResult } from "src/app/core/interfaces/global-search-result";
import { Router } from "@angular/router";

@Component({
  selector: "app-global-search-dialog",
  templateUrl: "./global-search-dialog.component.html",
  styleUrls: ["./global-search-dialog.component.scss"],
})
export class GlobalSearchDialogComponent implements OnInit, OnDestroy {
  isInitial: boolean = true;
  private searchSubject = new Subject<string>();
  private readonly debounceTimeMs = 300; // Set the debounce time (in milliseconds)
  searchSub$!: Subscription;
  searchResults: GlobalSearchResult[] = [];
  isLoading: boolean = false;
  isCancelIconVisible: boolean = false;
  searchKeyword!: string;

  constructor(
    private _dialogRef: MatDialogRef<GlobalSearchDialogComponent>,
    private _GlobalSearchService: GlobalSearchService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.searchSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe((searchValue) => {
      if (this.data.role === "supervisors") {
        this.supervisorSearchByNid(searchValue);
      }
    });
  }

  onSearch(searchValue: string) {
    this.searchSubject.next(searchValue);
  }

  supervisorSearchByNid(nid: string) {
    if (nid.length >= 1) {
      this.isCancelIconVisible = true;
    }
    if (nid.length >= 5) {
      this.isLoading = true;
      this.isInitial = false;
      this.searchSub$ = this._GlobalSearchService.supervisorSearchUser(nid).subscribe({
        next: (res: GlobalSearchResult[]) => {
          this.searchResults = res;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
    } else {
      this.isInitial = true;
    }
  }

  cancelSearch() {
    this.searchResults = [];
    this.isCancelIconVisible = false;
    this.isInitial = true;
    this.isLoading = false;
    this.searchKeyword = "";
  }

  closeDialog() {
    this._dialogRef.close();
  }

  navigateToDetails(item: GlobalSearchResult) {
    if (this.data.role === "supervisors") {
      this.router.navigateByUrl(`supervisor/${item.type === "case" ? "case-details" : "inquiry-details"}/${item.id}`);
      this.closeDialog();
    }
  }

  ngOnDestroy(): void {
    this.searchSub$?.unsubscribe();
  }
}
