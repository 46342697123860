<div class="notification" id="openNotify" #openNotify>
  <div class="notification__wrapper">
    <div class="notification__wrapper__header">
      <div class="d-flex">
        <mat-icon
          (click)="close()"
          aria-hidden="false"
          aria-label="close icon"
          class="material-icons-outlined close"
          fontIcon="close"
          >close</mat-icon
        >
        <h4>الاشعارات</h4>
      </div>
      <div>
        <div class="d-flex">
          <p>عرض الغير مقروءة فقط</p>
          <mat-icon
            [ngStyle]="{ display: toggleOn ? 'none' : 'block' }"
            aria-hidden="false"
            aria-label="toggle off icon"
            class="material-icons-outlined toggle toggle_off"
            fontIcon="toggle_off"
            (click)="toggle(true)"
            >toggle_off</mat-icon
          >
          <mat-icon
            [ngStyle]="{ display: !toggleOn ? 'none' : 'block' }"
            aria-hidden="false"
            aria-label="toggle on icon"
            class="material-icons-outlined toggle toggle_on"
            fontIcon="toggle_on"
            (click)="toggle(false)"
            >toggle_on</mat-icon
          >
        </div>
      </div>
    </div>
    <div class="notification__wrapper__actions">
      <button class="read-all-btn" mat-flat-button (click)="markAllAsRead()" *ngIf="notificationsCount.count > 0">
        اجعل الكل مقروء ({{ notificationsCount.count }})
      </button>
      <!-- Lawyer notifications filters -->
      <div class="d-flex actions-btns" *ngIf="role === 'lawyer'">
        <button
          mat-flat-button
          [ngClass]="{ active: currentAction == ActionsTypes.ALL }"
          (click)="changeAction(ActionsTypes.ALL)"
        >
          الكل
        </button>
        <button
          mat-flat-button
          (click)="changeAction(ActionsTypes.NEW_CASE, NotificationExtraType.newAssigment)"
          [ngClass]="{ active: currentAction == ActionsTypes.NEW_CASE }"
        >
          اسناد جديد
        </button>
        <button
          mat-flat-button
          (click)="changeAction(ActionsTypes.REOPEN, NotificationExtraType.reOpenCaseRequest)"
          [ngClass]="{ active: currentAction == ActionsTypes.REOPEN }"
        >
          إعادة فتح
        </button>
        <button
          mat-flat-button
          (click)="changeAction(ActionsTypes.NEW_SERVICE, NotificationExtraType.newComment)"
          [ngClass]="{ active: currentAction == ActionsTypes.NEW_SERVICE }"
        >
          خدمة جديدة أو معدلة
        </button>
        <button
          mat-flat-button
          (click)="changeAction(ActionsTypes.RATE, NotificationExtraType.newEvaluation)"
          [ngClass]="{ active: currentAction == ActionsTypes.RATE }"
        >
          تقييم المستفيد
        </button>
      </div>

      <!-- Supervisors notifications filters -->
      <div class="d-flex actions-btns" *ngIf="role === 'supervisor'">
        <button
          mat-flat-button
          [ngClass]="{ active: currentAction == ActionsTypes.ALL }"
          (click)="changeAction(ActionsTypes.ALL)"
        >
          الكل
        </button>
        <button
          mat-flat-button
          (click)="changeAction(ActionsTypes.CLOSE_REQUEST, NotificationExtraType.closeUserCaseRequest)"
          [ngClass]="{ active: currentAction == ActionsTypes.CLOSE_REQUEST }"
        >
          طلب إغلاق نهائي
        </button>
        <button
          mat-flat-button
          (click)="changeAction(ActionsTypes.REDIRECT, NotificationExtraType.directTo)"
          [ngClass]="{ active: currentAction == ActionsTypes.REDIRECT }"
        >
          طلب توجيه
        </button>
        <button
          mat-flat-button
          (click)="changeAction(ActionsTypes.REASSIGN, NotificationExtraType.reassignRequest)"
          [ngClass]="{ active: currentAction == ActionsTypes.REASSIGN }"
        >
          طلب إسناد لمحامي آخر
        </button>
      </div>
    </div>
    <div class="notification__wrapper__content">
      <div class="notification__wrapper__content_wrapper">
        <h6>الاحدث</h6>
        <div
          *ngFor="let notification of notifications; let i = index"
          class="notification__wrapper__content_wrapper__items"
        >
          <div
            [ngClass]="{ seen: notification.seen }"
            [ngStyle]="{
              cursor: notification?.notificationType ? 'pointer' : 'default'
            }"
            class="notification__wrapper__content_wrapper__items-item"
            (click)="
              notification?.notificationType
                ? goTo(notification.notificationType, notification.operationId, notification.id)
                : close()
            "
          >
            <p>{{ notification.message }}</p>
            <div *ngIf="!notification.seen">
              <i></i>
            </div>
          </div>
          <mat-divider *ngIf="notifications.length - (i + 2) >= 0"></mat-divider>
        </div>
        <div
          *ngIf="hasMoreNotifications"
          class="notification__wrapper__content_wrapper__load-more"
          (click)="loadMore()"
        >
          المزيد
        </div>
        <div *ngIf="!hasMoreNotifications" class="notification__wrapper__content_wrapper__no-more">لا يوجد المزيد</div>
      </div>
    </div>
  </div>
</div>
