export enum ActionsTypes {
  ALL = "all",
  NEW_CASE = "new-case",
  REOPEN = "reopen",
  NEW_SERVICE = "new-service",
  RATE = "rate",
}

export enum NotificationExtraType {
  newAssigment = "newAssigment",
  reOpenCaseRequest = "reOpenCaseRequest",
  newComment = "newComment",
  newEvaluation = "newEvaluation",
}
