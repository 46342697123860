<mat-toolbar *ngIf="navbarService.isNavbarVisible" class="navbar">
  <div class="menu">
    <!-- navbar logo  -->
    <div class="menu__logo">
      <div class="img" (click)="redirectToHomePage()">
        <img src="../../../../assets/images/logo.png" alt="logo" />
      </div>
      <div class="sperator"></div>
    </div>
    <!-- navbar links  -->
    <div class="menu__links" #overlay [class.supervisor-links]="auth.user.currentUser.roles[0] == 'supervisors'">
      <div class="menu__links_nav" #nav>
        <div class="menu__links_nav_header" *ngIf="this.navResponsive == true">
          <mat-icon
            (click)="makeItResponsive()"
            aria-hidden="false"
            aria-label="Example close icon"
            class="material-icons-outlined"
            >close</mat-icon
          >
          <div class="img" (click)="redirectToHomePage()">
            <img src="../../../../assets/images/logoo.png" alt="logo" />
          </div>
        </div>
        <a
          class="link"
          *ngIf="auth.user.currentUser && this.navResponsive == true"
          routerLink="/user/my-profile"
          (click)="makeItResponsive()"
          >{{ auth.user.currentUser.username }}</a
        >
        <a
          class="link"
          *ngIf="!auth.user.currentUser && this.navResponsive == true"
          [routerLink]="['/user/login']"
          (click)="makeItResponsive()"
          >تسجيل دخول</a
        >
        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'conciliators'"
          routerLink="/conciliator/cases"
          (click)="makeItResponsive()"
          routerLinkActive="active"
          ><div>طلبات الصلح</div></a
        >
        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'supervisors'"
          routerLink="/supervisor/cases"
          (click)="makeItResponsive()"
          routerLinkActive="active"
        >
          <div matTooltip="خدمات الترافع" matTooltipPosition="below" matTooltipClass="tooltip-silver">القضايا</div>
        </a>

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'supervisors'"
          routerLink="/supervisor/inquiries"
          (click)="makeItResponsive()"
          routerLinkActive="active"
        >
          <div matTooltip="الاستشارات" matTooltipPosition="below" matTooltipClass="tooltip-silver">الاستشارات</div>
        </a>

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'supervisors'"
          routerLink="/supervisor/legal-services"
          (click)="makeItResponsive()"
          routerLinkActive="active"
        >
          <div
            matTooltip="الخدمات القانونية الكتابية والاجرائية"
            matTooltipPosition="below"
            matTooltipClass="tooltip-silver"
          >
            الخدمات القانونية
          </div>
        </a>

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'supervisors'"
          routerLink="/supervisor/complains"
          (click)="makeItResponsive()"
          routerLinkActive="active"
          ><div>
            الشكاوي
            <span class="complainsIndicator" *ngIf="isPendingComplains"></span></div
        ></a>

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'supervisors'"
          routerLink="/supervisor/previous-cases"
          (click)="makeItResponsive()"
          routerLinkActive="active"
          ><div>القضايا و الخدمات السابقة</div></a
        >

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'lawyers'"
          routerLink="/lawyer/cases"
          [queryParams]="{ page: '1' }"
          (click)="makeItResponsive()"
          [ngClass]="{ active: navbarService.curentLink == 'cases' }"
          ><div>القضايا</div></a
        >
        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'lawyers'"
          routerLink="/lawyer/inquiries"
          [queryParams]="{ page: '1' }"
          routerLinkActive="active"
          [ngClass]="{ active: navbarService.curentLink == 'inquiries' }"
          (click)="makeItResponsive()"
          ><div>الاستشارات</div></a
        >

        <!-- <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'lawyers'"
          routerLink="/lawyer/legal-services"
          (click)="makeItResponsive()"
          routerLinkActive="active"
        >
          <div
            matTooltip="الخدمات القانونية الكتابية والاجرائية"
            matTooltipPosition="below"
            matTooltipClass="tooltip-silver"
          >
            الخدمات القانونية
          </div>
        </a> -->

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'lawyers'"
          routerLink="/lawyer/reports"
          (click)="makeItResponsive()"
          routerLinkActive="active"
          [ngClass]="{ active: navbarService.curentLink == 'reports' }"
          ><div>التقارير</div></a
        >

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'callcenter'"
          (click)="makeItResponsive()"
          routerLink="/call-center/my-applications"
          ><div>الرئيسية</div></a
        >

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'callcenter'"
          (click)="makeItResponsive()"
          (click)="openCreateCaseDialog()"
          ><div>تقديم قضية</div></a
        >

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'callcenter'"
          (click)="makeItResponsive()"
          routerLink="/call-center/create-inquiry"
          ><div>تقديم استشارة</div></a
        >

        <a
          class="link"
          *ngIf="auth.user.currentUser.roles[0] == 'callcenter'"
          (click)="makeItResponsive()"
          routerLink="/call-center/search-applications"
          ><div>استعلام</div></a
        >
        <div
          class="link logout"
          *ngIf="auth.user.currentUser && this.navResponsive == true"
          (click)="openLogoutDialog()"
        >
          <mat-icon class="material-icons-outlined" aria-hidden="false" aria-label="logout icon" fontIcon="logout"
            >logout</mat-icon
          >
          <p>تسجيل الخروج</p>
        </div>

        <div class="toggler link" *ngIf="auth.user.currentUser.roles[0] == 'lawyers' && this.navResponsive == true">
          <mat-slide-toggle [checked]="isBreak" (toggleChange)="toggleBreak()"></mat-slide-toggle>
          <span (click)="toggleBreak()">تفعيل استراحة</span>
        </div>

        <figure class="menu__links_img" *ngIf="this.navResponsive == true">
          <img [src]="profileImg" alt="الملف الشخصي" />
        </figure>
      </div>

      <div class="responsive" #navResp>
        <mat-icon id="menu" class="material-icons-outlined" (click)="makeItResponsive()"> menu </mat-icon>
        <div class="img" (click)="redirectToHomePage()">
          <img src="../../../../assets/images/logoo.png" alt="logo" />
        </div>
        <div class="responsive_icon">
          <mat-icon class="material-icons-outlined" *ngIf="auth.user.currentUser" routerLink="/user/my-profile">
            account_circle
          </mat-icon>
          <mat-icon
            *ngIf="!auth.user.currentUser"
            [routerLink]="['/user/login']"
            (click)="makeItResponsive()"
            class="material-icons-outlined"
            >logout</mat-icon
          >
          <mat-icon
            *ngIf="auth.user.currentUser"
            id="notify"
            class="material-icons-outlined"
            (click)="openNotification(this.navFlag)"
          >
            notifications
          </mat-icon>
          <div class="icon-counter" *ngIf="hasUnseenNotification > 0"></div>

          <!-- <div class="icon-wrapper">
            <mat-icon id="notify" class="material-icons-outlined" (click)="openNotification(this.navFlag)">
              notifications
            </mat-icon>
          </div> -->
        </div>
      </div>
    </div>
    <!-- navbar user toolset -->
    <div class="menu__user">
      <a *ngIf="!auth.user.currentUser" [routerLink]="['/user/login']">تسجيل دخول</a>
      <div class="user-search" *ngIf="auth.user.currentUser.roles[0] == 'supervisors'" (click)="openSearchDialog()">
        <mat-icon class="material-symbols-outlined">search</mat-icon>
      </div>
      <div *ngIf="auth.user.currentUser" class="user-status">
        <div class="status-dot-container" *ngIf="auth.user.currentUser.roles[0] == 'lawyers'">
          <span class="status-dot" [ngClass]="{ break: isBreak }">
            <mat-icon class="material-icons-outlined"> pause_circle </mat-icon>
          </span>
        </div>

        <div class="menu__user__name">
          <a routerLink="/user/my-profile" (mouseenter)="showUserPopup = true">{{ auth.user.currentUser.username }}</a>

          <ng-container *ngIf="auth.user.currentUser.roles[0] == 'lawyers'">
            <div *ngIf="showUserPopup" (mouseleave)="showUserPopup = false">
              <div class="popup" (mouseenter)="showUserPopup = true">
                <span routerLink="/user/my-profile">حسابك الشخصي</span>
                <mat-divider></mat-divider>
                <div class="toggler">
                  <mat-slide-toggle [checked]="isBreak" (toggleChange)="toggleBreak()"></mat-slide-toggle>
                  <span (click)="toggleBreak()">تفعيل استراحة</span>
                </div>
              </div>
              <div class="triangle-up" (mouseenter)="showUserPopup = true"></div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="icon-wrapper">
        <mat-icon id="notify" class="material-icons-outlined" (click)="openNotification(this.navFlag)">
          notifications
        </mat-icon>
        <div class="icon-counter" *ngIf="hasUnseenNotification > 0">{{ hasUnseenNotification }}</div>
      </div>
      <div
        matTooltip="تسجيل الخروج"
        matTooltipPosition="above"
        matTooltipClass="tooltip-grey"
        mat-button
        class="icon-wrapper nav-logout"
        *ngIf="auth.user.currentUser"
        (click)="openLogoutDialog()"
      >
        <mat-icon class="material-icons-outlined" aria-hidden="false" aria-label="logout icon" fontIcon="logout"
          >logout</mat-icon
        >
      </div>
      <!-- <div class="disabled-feature">تواصل معانا</div> -->
    </div>
  </div>
</mat-toolbar>
