import { Subscription } from "rxjs";
import { Component, ElementRef, OnInit, ViewChild, Renderer2, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Event, NavigationEnd, Router } from "@angular/router";
import { AuthService } from "src/app/core/services/auth.service";
import { CreateCaseComponent } from "src/app/features/case-type/dialogs/create-case/create-case.component";
import { CheckLoginDialogComponent } from "src/app/shared/dialogs/check-login-dialog/check-login-dialog.component";
import { NavbarService } from "../../service/navbar.service";
import { NotificationService } from "../notification/notification.service";
import { UserService } from "@kortobaa-front/authentication";
import { LogoutDialogComponent } from "src/app/auth/dialogs/logout-dialog/logout-dialog.component";
import { SocketService } from "../../service/socket.service";
import { Roles, SocketEvents } from "src/app/core/interfaces/general.interface";
import { GlobalSearchDialogComponent } from "src/app/shared/dialogs/global-search-dialog/global-search-dialog.component";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  navFlag: string = "close";
  hasUnseenNotification!: number;
  notifySub$!: Subscription;
  @ViewChild("nav") nav!: ElementRef;
  @ViewChild("navResp") navResp!: ElementRef;
  @ViewChild("overlay") overlay!: ElementRef;
  public screenWidth: any = window.innerWidth;
  navResponsive: boolean = false;
  profileImg!: string;
  public isBreak: boolean = false;
  public showUserPopup: boolean = false;
  supervisorPendingComplainSub$!: Subscription;
  isPendingComplains: boolean = false;
  routeSub$!: Subscription;

  constructor(
    public navbarService: NavbarService,
    public auth: AuthService,
    private _router: Router,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _auth: AuthService,
    private _notify: NotificationService,
    private _user: UserService,
    private renderer: Renderer2,
    private socketService: SocketService
  ) {
    this._notify.emitCloseDialog(this.navFlag);
    this.profileImg = `../../../../assets/images/${this.auth?.user?.currentUser?.roles[0]}-profile.svg`;
    this._notify.getCloseDialog().subscribe((data) => {
      this.navFlag = data;
    });
  }

  ngOnInit(): void {
    this._user.checkUserValid().subscribe((data: any) => {
      // Check if user role is lawyer and is valid user
      if (data && this.auth?.user?.currentUser?.roles[0] == Roles.lawyer) {
        this.socketService.connectSocket();
        const payload = {
          token: this._user.getToken(),
        };
        this.socketService.emitEvent(SocketEvents.CONNECT, payload);
      }

      if (data) {
        this._notify.getNotificationsCount().subscribe((data: any) => {
          this.hasUnseenNotification = data.count;
        });
      }
    });
    this._notify.getUserIsAuthticated().subscribe((data: any) => {
      this._notify.getNotificationsCount().subscribe((data: any) => {
        this.hasUnseenNotification = data.count;
      });
    });
    this._notify.getSeenNotification().subscribe((data: any) => {
      this._notify.getNotificationsCount().subscribe((data: any) => {
        this.hasUnseenNotification = data.count;
      });
    });

    if (this.screenWidth <= 1050) {
      this.renderer.listen("window", "click", (e: any) => {
        if (this.navResponsive == true && !this.nav.nativeElement.contains(e.target) && e.target?.id != "menu") {
          // this.navResponsive = false
          // this.nav.nativeElement.style.display = "none"
          // this.renderer.removeClass(this.overlay.nativeElement, 'overlay');
          this.makeItResponsive();
        }
      });
    }

    if (this.auth.user.currentUser.roles[0] === "supervisors") {
      this.routeSub$ = this._router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          this.supervisorPendingComplainSub$ = this._notify.getSupervisorPendingComplainsIndicator().subscribe({
            next: (res: any) => {
              this.isPendingComplains = res;
            },
          });
        }
      });
    }
  }

  /**
   * Toggles the break state of the user. If the user is on break,
   * disconnects the socket connection; otherwise, connects the socket.
   */

  public toggleBreak(): void {
    this.isBreak = !this.isBreak;
    if (this.isBreak) {
      this.navbarService.deActivateStatus();
    } else {
      this.navbarService.activateStatus();
    }
  }

  //open create case dialog
  openCreateCase() {}

  //logout from and popup a snackbar notification
  logout() {
    this.auth.user.logout();
    this._router.navigateByUrl("");
    this._snackBar.open("تم تسجيل الخروج", "X", {
      panelClass: "error-snackbar",
      // duration: 2000,
      // verticalPosition: "bottom",
    });
    this.navbarService.disconnectSocket();
  }

  openCreateCaseDialog() {
    const dialogRef = this._dialog.open(CreateCaseComponent, {
      panelClass: "white-dialog",
      width: "58rem",
      height: "39rem",
      autoFocus: false,
    });
  }

  redirectToMyApplications() {
    const isUserLogged = this.auth.user.currentUser;
    if (isUserLogged) {
      this._router.navigateByUrl("/cases/cases");
    } else {
      const dialogRef = this._dialog.open(CheckLoginDialogComponent, {
        panelClass: "white-dialog",
        width: "58rem",
        height: "39rem",
        data: {
          message: "برجاء تسجيل الدخول لاستعراض القضايا و الاستفسارات الخاصة بك",
        },
      });
    }
  }

  redirectToHomePage() {
    const role = this._auth.user.currentUser.roles[0];
    switch (role) {
      case "supervisors":
        this._router.navigateByUrl("/supervisor");
        break;
      case "lawyers":
        this._router.navigateByUrl("/lawyer");
        break;
      case "conciliators":
        this._router.navigateByUrl("/conciliator");
        break;
      case "callcenter":
        this._router.navigateByUrl("/call-center");
        break;
      default:
        this._router.navigateByUrl("/");
        break;
    }
    this.makeItResponsive();
  }

  openNotification(value: any) {
    this.navFlag = this.navFlag == "close" ? "open" : "close";
    this._notify.emitCloseDialog(this.navFlag);
    if (this.navFlag == "open") {
      // this.notifySub$ = this._notify.updateNotificationToSeen().subscribe((data) => {
      //   this.hasUnseenNotification = false;
      //   this._notify.emitSeenNotification();
      // });
      this._notify.emitOpenDialog();
    }
  }

  makeItResponsive() {
    if (this.screenWidth <= 1050) {
      if (this.navResponsive == false) {
        this.navResponsive = true;
        this.nav.nativeElement.style.display = "block";
        document.body.classList.add("hidden");
        this.overlay.nativeElement.classList.add("overlay");
      } else {
        this.navResponsive = false;
        this.nav.nativeElement.style.display = "none";
        document.body.classList.remove("hidden");
        this.renderer.removeClass(this.overlay.nativeElement, "overlay");
      }
    }
  }

  openLogoutDialog() {
    const dialogRef = this._dialog.open(LogoutDialogComponent, {
      panelClass: "white-dialog",
      width: "58rem",
      height: "39rem",
      autoFocus: false,
    });
    this.makeItResponsive();
  }

  openSearchDialog() {
    const dialogRef = this._dialog.open(GlobalSearchDialogComponent, {
      panelClass: "white-dialog",
      width: "60vw",
      maxWidth: "91rem",
      height: "70rem",
      maxHeight: "80vh",
      autoFocus: false,
      data: {
        role: this.auth.user.currentUser.roles[0],
      },
    });
  }

  ngOnDestroy(): void {
    if (this.notifySub$) this.notifySub$.unsubscribe();
    if (this.supervisorPendingComplainSub$) this.supervisorPendingComplainSub$.unsubscribe();
    if (this.routeSub$) this.routeSub$.unsubscribe();
  }
}
