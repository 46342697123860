<div class="global-search-dialog">
  <mat-icon class="material-symbols-outlined close-icon" (click)="closeDialog()">close</mat-icon>
  <h2 class="dialog-title">بحث عام</h2>
  <!-- search dialog search input  -->
  <div class="field">
    <mat-form-field class="global-search-dialog__input" appearance="outline">
      <input
        class="warning-border"
        matInput
        required
        type="text"
        [(ngModel)]="searchKeyword"
        maxlength="15"
        onkeypress="return /[0-9]/i.test(event.key)"
        (keyup)="onSearch(searchInput.value)"
        #searchInput
        placeholder="إبحث عن رقم قضية، إستشارة الخ.."
      />
      <div class="searchIcon" *ngIf="!isCancelIconVisible">
        <mat-icon class="material-symbols-outlined">search</mat-icon>
      </div>
      <div class="cancelIcon" (click)="cancelSearch()" *ngIf="isCancelIconVisible">
        <mat-icon class="material-symbols-outlined">close</mat-icon>
      </div>
    </mat-form-field>
  </div>
  <!-- search results -->
  <div class="global-search-dialog__results-container">
    <div class="results" *ngIf="!isLoading && searchResults.length > 0 && !isInitial">
      <ng-container *ngFor="let item of searchResults">
        <div class="result-card" (click)="navigateToDetails(item)">
          <mat-icon class="material-symbols-outlined">
            {{ item.type === "case" ? "gavel" : "description" }}
          </mat-icon>
          <p>{{ item.type === "case" ? item.caseTitle : "إستشارة" }}</p>
          <p>{{ item.application_no }}</p>
          <p>{{ item.username }}</p>
        </div>
        <mat-divider></mat-divider>
      </ng-container>
    </div>
    <div class="result-placeholder" *ngIf="!isLoading && isInitial">
      <mat-icon class="material-symbols-outlined">search</mat-icon>
      <p>عن ماذا تبحث ؟</p>
    </div>
    <div class="result-placeholder" *ngIf="!isLoading && searchResults.length === 0 && !isInitial">
      <img src="../../../../assets/images/no-filter.png" alt="no results" />
      <p>عذراً , لا توجد نتائج لهذا البحث</p>
    </div>
    <div class="result-placeholder" *ngIf="isLoading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
