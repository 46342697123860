import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserService } from "@kortobaa-front/authentication";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GlobalSearchService {
  constructor(private _http: HttpClient, private _user: UserService) {}

  userToken() {
    return {
      headers: { Authorization: `Bearer ${this._user.getToken()}` },
    };
  }
  supervisorSearchUser(nid: string): Observable<any> {
    return this._http.get(`${environment.api_url}supervisor/user-cases-inquiries/search?nid=${nid}`, this.userToken());
  }
}
