import { Subscription, take } from "rxjs";
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from "@angular/core";
import { NotificationService } from "./notification.service";
import { Router } from "@angular/router";
import { AuthService } from "src/app/core/services/auth.service";
import { ActionsTypes, NotificationExtraType } from "./types";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit {
  @ViewChild("openNotify") openNotify!: ElementRef;
  toggleOn: boolean = false;
  notifySub$!: Subscription;
  notifyAllSub$!: Subscription;
  notifyCountSub$!: Subscription;
  notifySeenSub$!: Subscription;
  notifyOpenSub$!: Subscription;
  notifyNewSub$!: Subscription;
  notifications: any = [];
  notificationsCount: any = [];
  infiniteScrollCounter: number = 10;
  hasMoreNotifications: boolean = true;
  role: string = "";
  notSeenNotifySub$!: Subscription;
  public currentAction = ActionsTypes.ALL;
  public currentFilter = "";
  public ActionsTypes = ActionsTypes;
  public NotificationExtraType = NotificationExtraType;
  constructor(
    private _notify: NotificationService,
    private _router: Router,
    private _auth: AuthService,
    private renderer: Renderer2
  ) {
    if (this._auth?.user?.currentUser?.roles[0] == "conciliators") this.role = "conciliator";
    if (this._auth?.user?.currentUser?.roles[0] == "supervisors") this.role = "supervisor";
    if (this._auth?.user?.currentUser?.roles[0] == "lawyers") this.role = "lawyer";
    this.notifySub$ = this._notify.getCloseDialog().subscribe((res) => {
      if (res == "close") {
        this.openNotify.nativeElement.style.display = "none";
        this.openNotify.nativeElement.children[0].style.width = "0";
        document.body.classList.remove("hidden");
      } else {
        document.body.classList.add("hidden");
        this.openNotify.nativeElement.style.display = "block";
        this.openNotify.nativeElement.children[0].style.width = "75rem";
      }
    });
    this.notifySeenSub$ = this._notify.getSeenNotification().subscribe((data) => {
      if (!data)
        this.notifications.forEach((notification: any) => {
          notification.seen = true;
        });
    });
    this.renderer.listen("window", "click", (e: any) => {
      if (!this.openNotify.nativeElement.children[0].contains(e.target) && e.target?.id != "notify") {
        this._notify.emitCloseDialog("close");
        document.body.classList.remove("hidden");
        this.toggleOn = false;
      }
    });
    this.notifyOpenSub$ = this._notify.getOpenDialog().subscribe((data) => {
      this.notifyAllSub$ = this._notify.getNotifications().subscribe((data) => {
        this.notifications = data;
        this.infiniteScrollCounter = 10;
        this.hasMoreNotifications = true;
      });
      this.notifyCountSub$ = this._notify.getNotificationsCount().subscribe((data) => (this.notificationsCount = data));
    });
    this.notifyNewSub$ = this._notify.getNewNotification().subscribe((data) => {
      this._notify.getNotifications().subscribe((data) => {
        this.notifications = data;
        this.infiniteScrollCounter = 10;
        this.hasMoreNotifications = true;
      });
      this._notify.getNotificationsCount().subscribe((data) => {
        this.notificationsCount = data;
        this._notify.emitSeenNotification();
      });
    });
  }

  ngOnInit(): void {}

  goTo(app: string, id: number, notificationId: number) {
    document.body.classList.remove("hidden");
    if (this._auth?.user?.currentUser?.roles[0] == "conciliators") this.role = "conciliator";
    if (this._auth?.user?.currentUser?.roles[0] == "supervisors") this.role = "supervisor";
    if (this._auth?.user?.currentUser?.roles[0] == "lawyers") this.role = "lawyer";
    this._notify.updateNotification(notificationId).subscribe((data: any) => {
      if (app != "message") {
        this._router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
          this._router.navigateByUrl(
            `${this.role}/${
              app == "case"
                ? `case-details`
                : app == "inquiry" || app == "inquiry.evaluation.answered"
                ? `inquiry-details`
                : `complain-details`
            }/${id}`
          );
        });
      }
      this._notify.emitCloseDialog("close");
      this.toggleOn = false;
      this._notify.emitSeenNotification();
    });
  }

  loadMore() {
    if (this.toggleOn === true) {
      this.infiniteScrollCounter = 20;
      this.getNotSeenNotification();
    } else {
      if (this.currentAction !== ActionsTypes.ALL) {
        this._notify
          .getMoreNotificationFilter(this.currentFilter, this.infiniteScrollCounter)
          .subscribe((data: any) => {
            if (data.length > 0) {
              this.notifications = [...this.notifications, ...data];
              this.infiniteScrollCounter += 10;
            } else {
              this.hasMoreNotifications = false;
            }
          });
      } else {
        this._notify.getNotificationsScroll(this.infiniteScrollCounter).subscribe((data: any) => {
          if (data.length > 0) {
            this.notifications = [...this.notifications, ...data];
            this.infiniteScrollCounter += 10;
          } else {
            this.hasMoreNotifications = false;
          }
        });
      }
    }
  }

  // close notification
  close() {
    this._notify.emitCloseDialog("close");
    this.toggleOn = false;
    document.body.classList.remove("hidden");
    this._notify.emitSeenNotification();
  }

  // toggle icon
  toggle(value: boolean) {
    this.toggleOn = value;
    this._notify.getNotifications().subscribe((data: any) => {
      this.notifications = value
        ? data.filter((notify: any) => {
            return notify.seen == false;
          })
        : data;
      if (value === true) {
        this.infiniteScrollCounter = 10;
        this.getNotSeenNotification();
      }
    });
  }

  // get not seen notification
  getNotSeenNotification() {
    this.notSeenNotifySub$ = this._notify.getNotificationSeen(this.infiniteScrollCounter).subscribe((data: any) => {
      if (data.length > 0) {
        this.notifications = [...this.notifications, ...data];
        this.infiniteScrollCounter += 10;
      } else {
        this.hasMoreNotifications = false;
      }
    });
  }

  public markAllAsRead(): void {
    this.notifications.forEach((notification: any) => {
      notification.seen = true;
      this._notify.updateNotification(notification.id).subscribe();
    });
  }

  public changeAction(action: ActionsTypes, filter?: NotificationExtraType): void {
    this.hasMoreNotifications = true;
    if (filter) {
      this.currentFilter = filter;
      this._notify
        .getNotificationFilter(this.currentFilter)
        .pipe(take(1))
        .subscribe((res) => {
          this.notifications = res;
        });
    }
    this.currentAction = action;
    if (action == ActionsTypes.ALL) {
      this._notify
        .getNotifications()
        .pipe(take(1))
        .subscribe((data) => {
          this.notifications = data;
        });
    }
  }

  ngOnDestroy(): void {
    if (this.notifySub$) this.notifySub$.unsubscribe();
    if (this.notifyAllSub$) this.notifyAllSub$.unsubscribe();
    if (this.notifyCountSub$) this.notifyCountSub$.unsubscribe();
    if (this.notifySeenSub$) this.notifySeenSub$.unsubscribe();
    if (this.notifyOpenSub$) this.notifyOpenSub$.unsubscribe();
    if (this.notifyNewSub$) this.notifyNewSub$.unsubscribe();
    if (this.notSeenNotifySub$) this.notSeenNotifySub$.unsubscribe();
  }
}
