import { Injectable } from "@angular/core";
import { SocketService } from "./socket.service";
import { UserService } from "@kortobaa-front/authentication";
import { SocketEvents } from "src/app/core/interfaces/general.interface";

@Injectable({
  providedIn: "root",
})
export class NavbarService {
  public curentLink = "";
  constructor(private socketService: SocketService, private _userService: UserService) {}

  isNavbarVisible: boolean = false;

  setNavbarVisiabilty(flag: boolean): void {
    this.isNavbarVisible = flag;
  }

  public activateStatus(): void {
    const payload = {
      token: this._userService.getToken(),
      action: "activate",
    };
    this.socketService.emitEvent(SocketEvents.UPDATE, payload);
  }

  public deActivateStatus(): void {
    const payload = {
      token: this._userService.getToken(),
      action: "deactivate",
    };
    this.socketService.emitEvent(SocketEvents.UPDATE, payload);
  }

  public disconnectSocket(): void {
    this.socketService.disconnect();
  }
}
