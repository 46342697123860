import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { RouterModule } from "@angular/router";

//material modules
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";

//components
import { NavbarComponent } from "./components/nav/navbar.component";
import { NotFoundPageComponent } from "./pages/not-found-page/not-found-page.component";
import { FooterComponent } from "./components/footer/footer.component";
import { OzwaDocComponent } from "./pages/ozwa-doc/ozwa-doc.component";
import { ContactUsDialogComponent } from "./dialog/contact-us-dialog/contact-us-dialog.component";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { NotificationComponent } from "./components/notification/notification.component";
import { MatDividerModule } from "@angular/material/divider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

@NgModule({
  declarations: [
    NavbarComponent,
    NotFoundPageComponent,
    FooterComponent,
    OzwaDocComponent,
    ContactUsDialogComponent,
    NotificationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    //material modules
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatDialogModule,
    MatDividerModule,
    MatTooltipModule,
    MatSlideToggleModule,
  ],
  exports: [NavbarComponent, FooterComponent, NotificationComponent],
})
export class UiModule {}
