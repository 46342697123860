import { Injectable } from "@angular/core";
import { io } from "socket.io-client";
import { environment } from "src/environments/environment";
declare const require: any;
const encryptIO = require("../../../assets/js/socketio-encrypt.js");

@Injectable({ providedIn: "root" })
export class SocketService {
  private socket: any;

  constructor() {}

  public connectSocket(): void {
    this.socket = io(environment.socket_url, {
      transports: ["websocket"],
      timeout: 40000,
    });
    encryptIO(environment.AES_KEY, environment.IV_LENGTH, environment.ENC_TYPE, environment.ENC_MODE)(this.socket);
  }

  // Method to emit events to the server
  public emitEvent(eventName: string, data: any): void {
    this.socket.emit(eventName, data);
  }

  // Method to listen for events from the server
  public onEvent(eventName: string, callback: Function): void {
    this.socket.on(eventName, callback);
  }

  // Method to disconnect from the server
  public disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
